<template>
  <div class="pageRoot">
    <SimonModule />
  </div>
</template>

<script>
import SimonModule from '@/modules/Simon'

export default {
  components: {
    SimonModule,
  },
}
</script>
